import React from "react";
import BlogArchive from "../components/BlogArchive";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Seo from "src/utils/seo"

export default class BlogArchiveTemplate extends React.Component {
  render() {
    const organizationSchema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "image": "https://www.example.com/example_image.jpg",
        "url": "https://www.summittraining.co.uk/",
        "logo": "https://www.example.com/images/logo.png",
        "name": "Summit Training",
        "description": "Tailored Management Training courses and Leadership Development courses designed by experts to deliver great results for your organisation.",
        "email": "info@summittraining.co.uk",
        "telephone": "0845 052 3701",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "33 Harrison Road",
          "addressLocality": "Halifax",
          "addressCountry": "UK",
          "addressRegion": "west-yorkshire",
          "postalCode": "HX1 2AF"
        }
      }
    return (
      <Layout location={this.props.location}>
          <Seo title={`Knowledge Centre - ${this.props.pageContext.tag}`} description={`Here you can find out all about ${this.props.pageContext.tag}!`} schema={organizationSchema} /> 
          <BlogArchive posts={this.props.data.posts.edges} numPages={this.props.pageContext.numPages} currentPage={this.props.pageContext.currentPage} tagSlug={this.props.pageContext.tagSlug}/>
      </Layout>
    );
  }
}

export const query = graphql`
    query getAllBlogPostsTags($skip: Int!, $limit: Int!, $tag: [String]) {
        posts: allStoryblokEntry(
            limit: $limit
            skip: $skip
            filter: {
                field_component: {eq: "post"},
                tag_list: {in: $tag}
            }
            sort: {order: DESC, fields: first_published_at}
        ) {
            edges {
                node {
                    name
                    content
                    full_slug
                    uuid
                    first_published_at
                    created_at
                }
            }
        }
    }
`;
